import * as React from "react"
import type { HeadFC } from "gatsby"
import { Link } from "gatsby"
import { Header } from "../components/Header"
import "./styles.scss"

const links = [
  {
    text: "Twitter",
    userName: "@nakaokat",
    url: "https://twitter.com/nakaokat",
  },
  {
    text: "GitHub",
    userName: "@nakaokat",
    url: "https://github.com/nakaokat",
  },
  {
    text: "はてなブログ",
    userName: "id:nakaoka3",
    url: "https://kyo-nikki.hatenablog.com",
  },
]


const IndexPage = () => {
  return (<>
    <Header />
    <main className="container">
      <h2>Links</h2>
      <ul>
        {links.map(link => (
          <li key={link.url}>
            <p>{link.text}: <a href={link.url}>{link.userName}</a></p>
          </li>
        ))}
      </ul>
      <h2>Pages</h2>
      <Link to="/仕事">仕事</Link>
    </main>
  </>)
}

export default IndexPage

export const Head: HeadFC = () => <title>nakaokat.net</title>
