import * as React from "react"
import { Link } from "gatsby"
import "../pages/styles.scss"

/**
 * Webページのヘッダーを表示するコンポーネント
 * トップへのリンクと、サイトのタイトルを表示する
 * @returns 
 */
export const Header = () => {
    const profile = {
      name : "Taiki Nakaoka",
      job : "Software Engineer",
    }
    return (
    <header>
      <div className="container">
          <h1><Link to="/">{profile.name}</Link></h1>
          <p>{profile.job}</p>
      </div>
    </header>
  )
}

